<template>
<div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />
    <CarrierList
        v-bind:tableItems="listVehicles"
        v-bind:totalPages="totalPages"
        v-bind:companyCodes="companyCodes"
        v-bind:carrierList="carriers"
        @vehicle-list="loadData"
        @carrier-selector="loadCarriers"
        @reset-carrier-list="resetCarrierList"
    />
</div>
</template>
<script>
import CarrierList from '../components/VehicleList.vue';
export default {
    name: "Vehicles",
    components: {
        CarrierList
    },
    data() {
        return {
            loading: false,
            listVehicles: [],
            totalPages: 0,
            companyCodes: [],
            carriers: [],
            toastShow: false,
            toastColor: "",
            toastMessage: "",
        }
    },
    computed: {
        carrierId() {
            let carrier = JSON.parse(localStorage.getItem("carrierInfo"));
            return carrier ? carrier.id : null;
        }
    },
    async mounted() {
        var rol = JSON.parse(localStorage.getItem("userRol"));
        var country = JSON.parse(localStorage.getItem("userCountry"))
        if (rol == 'logisticAdmin' && country) {
            await this.loadCompanyCodes(country)
        }
    },
    methods: {
        async loadData(filter) {
            const self = this;
            self.loading = true;
            try {
                const res = await self.$http.post("apiConsole/console/vehiclesAssignedToCarrier", filter);
                if (res.data) {
                    this.listVehicles = res.data.map(x=>{return {...x, loadVolume: x.loadVolume ||''}});
                }
            } catch (error) {
                console.log(error);
            } finally {
                self.loading = false;
            }
        },
        showToastInfo(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "success";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
        async loadCompanyCodes(country) {
            const self = this;
            self.companyCodes = [];
            try {
                const res = await self.$http.get(`apiConsole/console/companyCodes/${country}`);
                self.companyCodes = res.data.map(cc => ({ 
                code: cc.companyCode_CD,
                _key: cc.companyCode_CD,
                value: cc.companyCode_Name 
                }));
                console.log(self.companyCodes, 'self.companyCodes')
            } catch (e) {
                if (e.response) {
                const res = e.response;
                console.log(res.data.message);
                }
                console.error(e);
            }
        },
        async loadCarriers(data) {
            const self = this;
            self.loading = true;
            try {
                const res = await self.$http.post(`apiConsole/console/getCarriers`, {
                country: data.country,
                companyCode: data.companyCode,
                carrier: data.carrier
                });
                
                if(!res.data.length) {
                this.showToastInfo(
                    self.$t('carrier_not_found')
                );
                self.carriers = []
                self.listVehicles = []
                } else {
                    self.listVehicles = []
                    self.carriers = res.data.map(e => ({
                        _key: e.carrier,
                        source: e.source,
                        value: `[${e.carrier}] ${e.name}`
                    }));
                }
            } catch (e) {
                if (e.response) {
                const res = e.response;
                console.log(res.data.message);
                }
                console.error(e);
            } finally {
                self.loading = false;
            }
        },
        resetCarrierList() {
            this.carriers = []
        }
    }
    
}
</script>